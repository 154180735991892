@import "theme/variables.scss";

@mixin mobile {
  @media (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin row() {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin wrapper() {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@mixin title() {
  font-size: 20px;
  color: var(--ft-color-black2);
  font-weight: bold;

  i {
    font-style: normal;
    font-weight: 400;
    color: var(--ft-color-gray6);
    font-size: 12px;
    margin-left: 4px;
  }
}

@mixin icon-size($size) {
  width: $size;
  height: $size;
  margin: 8px;
  -webkit-user-drag: none;

  ion-icon {
    font-size: $size;
  }
}

@mixin full-horizontal-button($button-count) {
  $margin-horizontal: if($button-count > 1, 6px, 4px);

  ion-button {
    width: calc(100% / #{$button-count});
    height: 50px;
    margin: 0 #{$margin-horizontal};
    --border-radius: 4px !important;
    font-size: 16px;
  }
}
