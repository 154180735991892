@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/** Ionic CSS Variables **/
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@angular/cdk/overlay-prebuilt.css";
:root {
  --web-header-height: 80px;
  --web-content-width: 100%;
  --web-content-min-width: 1024px;
  --web-content-max-width: 1280px;
  --web-content-padding-top: 8px;
  --web-content-padding-bottom: 4px;
  --web-content-padding-left: 16px;
  --web-content-padding-right: 16px;
  --font-size: 14px;
  --ft-color-gray1-light: rgba(var(--ft-color-gray1-rgb), 0.1);
  --ft-color-white: #ffffff;
  --ft-color-white-rgb: 255, 255, 255;
  --ft-color-gray1: #eff2f6;
  --ft-color-gray1-rgb: 239, 242, 246;
  --ft-color-gray2: #dfe6ee;
  --ft-color-gray2-rgb: 223, 230, 238;
  --ft-color-gray3: #cfd6de;
  --ft-color-gray3-rgb: 207, 214, 222;
  --ft-color-gray4: #b0bac5;
  --ft-color-gray4-rgb: 176, 186, 197;
  --ft-color-gray5: #8091a4;
  --ft-color-gray5-rgb: 128, 145, 164;
  --ft-color-gray6: #667587;
  --ft-color-gray6-rgb: 102, 117, 135;
  --ft-color-black1: #495057;
  --ft-color-black1-rgb: 73, 80, 87;
  --ft-color-black2: #1e1e1e;
  --ft-color-black2-rgb: 30, 30, 30;
  --ft-color-main: #206ff6;
  --ft-color-main-rgb: 32, 111, 246;
  --ft-color-sub1: #01c2b2;
  --ft-color-sub1-rgb: 1, 194, 178;
  --ft-color-sub2: #d4fe42;
  --ft-color-sub2-rgb: 212, 254, 66;
  --ft-color-sub3: #c35bf5;
  --ft-color-sub3-rgb: 195, 91, 245;
  --ft-color-sub4: #ff2d55;
  --ft-color-sub4-rgb: 255, 45, 85;
  --ft-color-error: #ff2d55;
  --ft-color-error-rgb: 255, 45, 85;
  --ft-color-caution: #f7ba02;
  --ft-color-caution-rgb: 247, 186, 2;
  --ft-color-menubar: #fdfdfd;
  --ft-item-height: 62px;
  --ft-color-naver: #03c75a;
  --ft-color-naver-rgb: 3, 199, 90;
  --health-color: #ff6384;
  --pt-color: #ffcd56;
  --gx-color: #4bc0c0;
  --point-color: #c35bf5;
  --etc-color: #36a2eb;
  --etcs-color: #36a2eb;
  --ft-color-runner-interval-slow-rgb: 64, 220, 139;
  --ft-color-runner-interval-fast-rgb: 255, 213, 140;
  /** primary **/
  --ion-color-primary: var(--ft-color-main);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: var(--ft-color-sub4);
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: var(--ft-color-black1);
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: var(--ft-color-gray2);
  --ion-color-light-rgb: 223, 230, 238;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c4cad1;
  --ion-color-light-tint: #e2e9f0;
  --ion-color-naver-green: #03c75a;
  --item-border-color: var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --ion-item-border-color: var(--ft-color-gray2);
}

.ion-color-white {
  --ion-color-base: #ffffff !important;
  --ion-color-base-rgb: 255, 255, 255 !important;
  --ion-color-contrast: #ffffff !important;
  --ion-color-contrast-rgb: 255, 255, 255 !important;
  --ion-color-shade: #ffffff !important;
  --ion-color-tint: #ffffff !important;
}

.ion-color-main {
  --ion-color-base: var(--ft-color-main) !important;
  --ion-color-base-rgb: 32, 111, 246 !important;
  --ion-color-tint: rgba(var(--ion-color-base-rgb), 0.8) !important;
}

.ion-color-sub1 {
  --ion-color-base: var(--ft-color-sub1) !important;
}

.ion-color-sub2 {
  --ion-color-base: var(--ft-color-sub2) !important;
}

.ion-color-sub3 {
  --ion-color-base: var(--ft-color-sub3) !important;
}

.ion-color-sub4 {
  --ion-color-base: var(--ft-color-sub4) !important;
}

.ion-color-error {
  --ion-color-base: var(--ft-color-error) !important;
}

.ion-color-caution {
  --ion-color-base: var(--ft-color-caution) !important;
}

.ion-color-gray1 {
  --ion-color-base: var(--ft-color-gray1) !important;
}

.ion-color-gray2 {
  --ion-color-base: var(--ft-color-gray3) !important;
}

.ion-color-gray3 {
  --ion-color-base: var(--ft-color-gray3) !important;
}

.ion-color-gray4 {
  --ion-color-base: var(--ft-color-gray4) !important;
}

.ion-color-gray5 {
  --ion-color-base: var(--ft-color-gray5) !important;
}

.ion-color-gray6 {
  --ion-color-base: var(--ft-color-gray6) !important;
}

.ion-color-black1 {
  --ion-color-base: var(--ft-color-black1) !important;
}

.ion-color-black2 {
  --ion-color-base: var(--ft-color-black2) !important;
}

/** Ionic CSS Variables **/
:root {
  --web-header-height: 80px;
  --web-content-width: 100%;
  --web-content-min-width: 1024px;
  --web-content-max-width: 1280px;
  --web-content-padding-top: 8px;
  --web-content-padding-bottom: 4px;
  --web-content-padding-left: 16px;
  --web-content-padding-right: 16px;
  --font-size: 14px;
  --ft-color-gray1-light: rgba(var(--ft-color-gray1-rgb), 0.1);
  --ft-color-white: #ffffff;
  --ft-color-white-rgb: 255, 255, 255;
  --ft-color-gray1: #eff2f6;
  --ft-color-gray1-rgb: 239, 242, 246;
  --ft-color-gray2: #dfe6ee;
  --ft-color-gray2-rgb: 223, 230, 238;
  --ft-color-gray3: #cfd6de;
  --ft-color-gray3-rgb: 207, 214, 222;
  --ft-color-gray4: #b0bac5;
  --ft-color-gray4-rgb: 176, 186, 197;
  --ft-color-gray5: #8091a4;
  --ft-color-gray5-rgb: 128, 145, 164;
  --ft-color-gray6: #667587;
  --ft-color-gray6-rgb: 102, 117, 135;
  --ft-color-black1: #495057;
  --ft-color-black1-rgb: 73, 80, 87;
  --ft-color-black2: #1e1e1e;
  --ft-color-black2-rgb: 30, 30, 30;
  --ft-color-main: #206ff6;
  --ft-color-main-rgb: 32, 111, 246;
  --ft-color-sub1: #01c2b2;
  --ft-color-sub1-rgb: 1, 194, 178;
  --ft-color-sub2: #d4fe42;
  --ft-color-sub2-rgb: 212, 254, 66;
  --ft-color-sub3: #c35bf5;
  --ft-color-sub3-rgb: 195, 91, 245;
  --ft-color-sub4: #ff2d55;
  --ft-color-sub4-rgb: 255, 45, 85;
  --ft-color-error: #ff2d55;
  --ft-color-error-rgb: 255, 45, 85;
  --ft-color-caution: #f7ba02;
  --ft-color-caution-rgb: 247, 186, 2;
  --ft-color-menubar: #fdfdfd;
  --ft-item-height: 62px;
  --ft-color-naver: #03c75a;
  --ft-color-naver-rgb: 3, 199, 90;
  --health-color: #ff6384;
  --pt-color: #ffcd56;
  --gx-color: #4bc0c0;
  --point-color: #c35bf5;
  --etc-color: #36a2eb;
  --etcs-color: #36a2eb;
  --ft-color-runner-interval-slow-rgb: 64, 220, 139;
  --ft-color-runner-interval-fast-rgb: 255, 213, 140;
  /** primary **/
  --ion-color-primary: var(--ft-color-main);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: var(--ft-color-sub4);
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: var(--ft-color-black1);
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: var(--ft-color-gray2);
  --ion-color-light-rgb: 223, 230, 238;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c4cad1;
  --ion-color-light-tint: #e2e9f0;
  --ion-color-naver-green: #03c75a;
  --item-border-color: var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --ion-item-border-color: var(--ft-color-gray2);
}

.ion-color-white {
  --ion-color-base: #ffffff !important;
  --ion-color-base-rgb: 255, 255, 255 !important;
  --ion-color-contrast: #ffffff !important;
  --ion-color-contrast-rgb: 255, 255, 255 !important;
  --ion-color-shade: #ffffff !important;
  --ion-color-tint: #ffffff !important;
}

.ion-color-main {
  --ion-color-base: var(--ft-color-main) !important;
  --ion-color-base-rgb: 32, 111, 246 !important;
  --ion-color-tint: rgba(var(--ion-color-base-rgb), 0.8) !important;
}

.ion-color-sub1 {
  --ion-color-base: var(--ft-color-sub1) !important;
}

.ion-color-sub2 {
  --ion-color-base: var(--ft-color-sub2) !important;
}

.ion-color-sub3 {
  --ion-color-base: var(--ft-color-sub3) !important;
}

.ion-color-sub4 {
  --ion-color-base: var(--ft-color-sub4) !important;
}

.ion-color-error {
  --ion-color-base: var(--ft-color-error) !important;
}

.ion-color-caution {
  --ion-color-base: var(--ft-color-caution) !important;
}

.ion-color-gray1 {
  --ion-color-base: var(--ft-color-gray1) !important;
}

.ion-color-gray2 {
  --ion-color-base: var(--ft-color-gray3) !important;
}

.ion-color-gray3 {
  --ion-color-base: var(--ft-color-gray3) !important;
}

.ion-color-gray4 {
  --ion-color-base: var(--ft-color-gray4) !important;
}

.ion-color-gray5 {
  --ion-color-base: var(--ft-color-gray5) !important;
}

.ion-color-gray6 {
  --ion-color-base: var(--ft-color-gray6) !important;
}

.ion-color-black1 {
  --ion-color-base: var(--ft-color-black1) !important;
}

.ion-color-black2 {
  --ion-color-base: var(--ft-color-black2) !important;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

body.tour-open {
  overflow: hidden;
}

ngx-guided-tour .guided-tour-user-input-mask {
  z-index: 1081;
}
ngx-guided-tour .guided-tour-spotlight-overlay {
  z-index: 1082;
}
ngx-guided-tour .tour-orb {
  z-index: 1079;
  background-color: #625aff;
  box-shadow: 0 0 0.3rem 0.1rem #625aff;
}
ngx-guided-tour .tour-orb .tour-orb-ring::after {
  border: 1rem solid #625aff;
  box-shadow: 0 0 0.1rem 0.1rem #625aff;
}
ngx-guided-tour .tour-step {
  z-index: 1083;
}
ngx-guided-tour .tour-step.tour-bottom .tour-arrow::before, ngx-guided-tour .tour-step.tour-bottom-right .tour-arrow::before, ngx-guided-tour .tour-step.tour-bottom-left .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-bottom: 1rem solid #ffffff;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
}
ngx-guided-tour .tour-step.tour-top .tour-arrow::before, ngx-guided-tour .tour-step.tour-top-right .tour-arrow::before, ngx-guided-tour .tour-step.tour-top-left .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-top: 1rem solid #ffffff;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
}
ngx-guided-tour .tour-step.tour-left .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-left: 1rem solid #ffffff;
  border-bottom: 1rem solid transparent;
  border-top: 1rem solid transparent;
}
ngx-guided-tour .tour-step.tour-right .tour-arrow::before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-right: 1rem solid #ffffff;
  border-bottom: 1rem solid transparent;
  border-top: 1rem solid transparent;
}
ngx-guided-tour .tour-step .tour-block {
  color: #231f1f;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 0.6rem #4c4c4c;
}
ngx-guided-tour .tour-step .tour-buttons button.skip-button {
  color: #5e5e5e;
}
ngx-guided-tour .tour-step .tour-buttons .back-button {
  color: #007bff;
}
ngx-guided-tour .tour-step .tour-buttons .next-button {
  background-color: #007bff;
  color: #ffffff;
}
ngx-guided-tour .tour-step .tour-buttons .next-button:hover {
  background-color: #0069d9;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  ngx-guided-tour .tour-step.page-tour-step {
    width: 80% !important;
  }
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 700;
  src: url("^assets/font/SpoqaHanSansNeo-Bold.woff2") format("woff2"), url("^assets/font/SpoqaHanSansNeo-Bold.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 400;
  src: url("^assets/font/SpoqaHanSansNeo-Regular.woff2") format("woff2"), url("^assets/font/SpoqaHanSansNeo-Regular.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: 300;
  src: url("^assets/font/SpoqaHanSansNeo-Light.woff2") format("woff2"), url("^assets/font/SpoqaHanSansNeo-Light.eot") format("embedded-opentype");
}
@font-face {
  font-family: "TmoneyRoundWind";
  font-weight: 800;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.eot?#iefix") format("embedded-opentype"), url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.woff2") format("woff2"), url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.woff") format("woff"), url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.ttf") format("truetype");
  font-display: swap;
}
@page {
  size: auto;
  /* auto is the initial value */
  margin: 1mm auto;
  /* this affects the margin in the printer settings */
}
.full-screen-scroll {
  display: block !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
.full-screen-scroll .ion-page {
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  display: unset;
  position: unset;
  flex-direction: unset;
  justify-content: unset;
  contain: unset;
  overflow: unset;
  z-index: unset;
}
.full-screen-scroll ion-router-outlet {
  position: unset;
}
.full-screen-scroll .full-screen-container > * {
  display: block;
  min-width: var(--web-content-min-width);
  max-width: var(--web-content-max-width);
  margin: 0 auto;
}
.full-screen-scroll .full-screen-contents {
  display: block;
  min-width: var(--web-content-min-width);
  max-width: var(--web-content-max-width);
  margin: 0 auto;
}

.homepage-section {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
}
.homepage-section .section-header {
  flex: none;
  margin-top: 16px;
}
.homepage-section .section-header > ion-label {
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  .homepage-section .section-header > ion-label {
    font-size: 20px;
  }
}
.homepage-section .section-header > ion-button {
  font-size: 14px;
}
.homepage-section .section-body {
  flex: 1;
  width: 100%;
}

.back-cover-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.back-contain-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.rolling-banner .rolling-banner-image {
  width: 60%;
  height: 100%;
  padding: 16px;
}
.rolling-banner .rolling-banner-container {
  width: 40%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
.rolling-banner .rolling-banner-container * {
  margin: 0;
}
.rolling-banner .rolling-banner-container .rolling-banner-text {
  font-size: 30px;
}
.rolling-banner .rolling-banner-container .rolling-banner-desc {
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .rolling-banner ion-slide {
    display: flex;
    flex-direction: column;
  }
  .rolling-banner .rolling-banner-image {
    width: 100%;
    height: 60%;
    padding: 16px;
  }
  .rolling-banner .rolling-banner-container {
    width: 100%;
    height: 40%;
  }
}

#homepage-main > :first-child {
  overflow-y: auto;
  padding: 0 !important;
}
#homepage-main > :first-child > div {
  flex: none;
}

body {
  color: var(--ft-color-black1);
  --ion-text-color: var(--ft-color-black1);
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust: none;
}

* {
  font-family: "Spoqa Han Sans", "본고딕", sans-serif !important;
  font-weight: 400;
  letter-spacing: 0;
}

ion-button.button-outline {
  --border-radius: 4px !important;
}
ion-button.circle {
  width: 40px;
  height: 40px;
  --border-radius: 20px !important;
  margin: 0 4px;
}

ion-button ion-icon + ion-label {
  margin-left: 4px;
}

i {
  font-style: normal !important;
}

ion-buttons.bottom-2-button {
  padding: 10px;
}
ion-buttons.bottom-2-button ion-button {
  width: calc(100% / 2);
  height: 50px;
  margin: 0 6px;
  --border-radius: 4px !important;
  font-size: 16px;
}
ion-buttons.bottom-1-button {
  padding: 10px;
}
ion-buttons.bottom-1-button ion-button {
  width: calc(100% / 1);
  height: 50px;
  margin: 0 4px;
  --border-radius: 4px !important;
  font-size: 16px;
}

.plt-desktop {
  font-family: sans-serif;
  font-size: 14px;
}
.plt-desktop #main {
  margin: 0 auto;
}
.plt-desktop #main .ion-page {
  margin-top: -1px;
}
.plt-desktop #web-main > .ion-page {
  background: var(--ion-background-color, #fff);
  padding-left: var(--web-content-padding-left);
  padding-right: var(--web-content-padding-left);
}
.plt-desktop ion-popover {
  --width: 320px;
}
.plt-desktop ion-toolbar > ion-buttons {
  padding: 10px;
}

ion-modal ion-toolbar > ion-buttons {
  padding: 8px 0;
}

.webpage-wrapper, .plt-desktop #main > .ion-page:not(app-login) > ion-content > * {
  width: var(--web-content-width);
  margin: 0 auto;
}

.webpage-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  cursor: default !important;
}
.webpage-wrapper .fixed {
  flex: none;
}
.webpage-wrapper .flexable {
  flex: 1;
  overflow-y: hidden;
}
.webpage-wrapper ion-input,
.webpage-wrapper ion-button {
  height: 36px;
  border-radius: 6px !important;
  --border-radius: 6px !important;
}
.webpage-wrapper ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}
.webpage-wrapper .horizontal-items {
  display: flex;
  flex-direction: row;
}
.webpage-wrapper .horizontal-items > * {
  align-self: center;
}

.title-container {
  padding-top: var(--web-content-padding-top);
  padding-bottom: var(--web-content-padding-bottom);
}
.title-container .small-element {
  height: 36px;
  margin-left: 16px;
}
.title-container .big-element {
  height: 44px;
  margin-left: 16px;
}

.main-title {
  font-size: 20px !important;
  font-weight: bold !important;
  cursor: default !important;
}

ion-footer ion-button {
  min-width: 60px;
  font-size: 14px !important;
}
ion-footer .copyright {
  margin: 30px auto 20px auto;
  font-size: 11px;
  color: var(--ft-color-gray6);
  text-align: center;
}

ion-icon, ion-img, img, a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.paragraph {
  word-break: keep-all !important;
  white-space: pre-line !important;
  white-space: -moz-pre-line !important;
  white-space: -pre-line !important;
  white-space: -o-pre-line !important;
  word-wrap: break-word !important;
}

.ellipsis {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  overflow: hidden !important;
}

.icon-16 {
  width: 16px;
  height: 16px;
  margin: 8px;
  -webkit-user-drag: none;
}
.icon-16 ion-icon {
  font-size: 16px;
}

.icon-24 {
  width: 24px;
  height: 24px;
  margin: 8px;
  -webkit-user-drag: none;
}
.icon-24 ion-icon {
  font-size: 24px;
}

.icon-32 {
  width: 32px;
  height: 32px;
  margin: 8px;
  -webkit-user-drag: none;
}
.icon-32 ion-icon {
  font-size: 32px;
}

.icon-shadow {
  filter: drop-shadow(1px 1px 1px dimgray);
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.font-bold {
  font-weight: bold !important;
}

.fixed-flex-layout {
  display: flex;
  align-items: center;
}
.fixed-flex-layout > :nth-child(1) {
  margin-bottom: auto;
}
.fixed-flex-layout > :nth-child(2) {
  flex: 1;
  margin-bottom: auto;
}

.flex-fixed-layout {
  display: flex;
  align-items: center;
}
.flex-fixed-layout > :nth-child(1) {
  flex: 1;
}
.flex-fixed-layout > :nth-child(2) {
  flex: none;
}

.stripe-list ion-item:nth-child(2n) {
  background-color: WhiteSmoke;
}
.stripe-list ion-item:nth-child(2n+1) {
  background-color: White;
}
.stripe-list .stripe-item:nth-child(2n) {
  background-color: WhiteSmoke;
}
.stripe-list .stripe-item:nth-child(2n+1) {
  background-color: White;
}

.login-container {
  max-width: 360px;
}

.no-padding {
  padding: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}

.no-margin {
  margin: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

ion-avatar {
  margin-bottom: auto;
}

.avatar-border {
  box-shadow: 0 0 1px 2px var(--ft-color-main);
  margin: 2px;
}

.avatar-border-inactive {
  box-shadow: 0 0 1px 2px var(--ft-color-gray6);
  margin: 2px;
}

ion-checkbox {
  margin: 0 8px;
}

ion-input {
  --padding-start: 8px !important;
  --padding-end: 8px !important;
}

ion-button {
  --border-width: 1px;
}
ion-button.normal-circle {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-bottom: 4px;
  --padding-top: 4px;
}
ion-button.large-circle {
  --padding-start: 14px;
  --padding-end: 14px;
  --padding-bottom: 4px;
  --padding-top: 4px;
}

ion-item {
  --background-focused: transparent;
  --highlight-color-focused: var(--ft-color-main);
  --highlight-color-valid: var(--ft-color-main);
}
ion-item.focus-hover {
  --background-focused: var(--ion-item-background-hover, rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.04));
}
ion-item[detail] {
  cursor: pointer;
}

.searchbar-container .searchbar-input, .input-container {
  align-items: center;
  width: 100%;
  border: 1px solid var(--ft-color-gray4);
  border-radius: 4px;
}

.input-container {
  min-height: 44px;
  font-size: 14px;
}
.input-container.has-focus {
  border: 1px solid var(--ft-color-main);
}
.input-container.post-unit {
  flex-direction: row-reverse;
}
.input-container > :nth-child(1) {
  padding: 0 8px;
}
.input-container > textarea {
  flex: 1;
  padding: 8px !important;
}

.searchbar-container .searchbar-input {
  box-shadow: none;
}
.searchbar-container.searchbar-has-focus .searchbar-input {
  border: 1px solid var(--ft-color-main);
}

.ion-searchbar-font12 ion-searchbar .searchbar-input-container input {
  font-size: 12px !important;
}

.input-panel ion-select {
  max-width: unset;
  width: -webkit-fill-available;
  border: 1px solid var(--ft-color-gray4);
  border-radius: 4px;
  padding-right: 4px;
}
.input-panel ion-datetime {
  border: 1px solid var(--ft-color-gray4);
  border-radius: 4px;
}
.input-panel ion-datetime.full {
  width: 100%;
  margin: 0;
}

.error-message p {
  text-align: right;
  color: var(--ft-color-error);
}

.input-button {
  z-index: 2;
  margin-left: auto;
  --padding-start: 6px;
  --padding-end: 6px;
}

.cloned-input {
  top: unset !important;
}

.copyright {
  margin: 30px auto 20px auto;
  font-size: 11px;
  color: var(--ft-color-gray6);
}

.anim-sliding-box {
  background-color: inherit;
  max-height: 0px;
  overflow-y: hidden;
  transition: ease-in-out 300ms max-height;
}
.anim-sliding-box.opened {
  max-height: 1000px;
  transition: ease-in-out 300ms max-height;
}

.list-spinner {
  display: flex;
  justify-content: ceitem-innernter;
  margin: 20px auto;
}

ion-radio-group.single-line {
  display: flex;
}
ion-radio-group.single-line ion-item {
  --padding-start: 0;
}
ion-radio-group.single-line ion-radio {
  margin-right: 8px;
}

.map-loading {
  display: block;
  transform: scale(2);
  margin: 50% auto 0 auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.swipeable-modal ion-backdrop {
  --backdrop-opacity: 0.4;
  pointer-events: none;
}
.swipeable-modal .modal-wrapper {
  min-width: 320px;
  max-height: 96vh;
  margin: 4vh 0 0;
  border-radius: 16px 16px 0 0;
}
.swipeable-modal .modal-wrapper .ion-page {
  display: unset;
  position: unset;
}
.swipeable-modal .modal-wrapper .ion-page ion-header > ion-toolbar {
  padding: 0;
}

.alert-modal .modal-wrapper {
  min-width: 320px;
  max-height: 90%;
  height: auto;
}
.alert-modal .modal-wrapper .ion-page {
  display: unset;
  position: unset;
}
.alert-modal .modal-wrapper .modal-content {
  max-height: 50vh;
}
.alert-modal .modal-wrapper .modal-content .modal-content-list {
  overflow-y: auto;
  max-height: 40vh;
  min-height: 48px;
}
@media only screen and (max-width: 400px) {
  .alert-modal .modal-wrapper {
    max-width: 90%;
  }
}
@media only screen and (min-width: 401px) {
  .alert-modal .modal-wrapper {
    max-width: 360px;
  }
}

.background-image-center {
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}

.segment-no-indicator {
  --indicator-color: transparent !important;
  --indicator-color-checked: transparent !important;
}

ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height.bottom {
  align-items: flex-end;
}
ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
  padding: 10px;
}

.email-auth-confirm .modal-wrapper {
  width: 280px;
  height: 264px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.payment-password-modal .modal-wrapper {
  width: 420px;
  min-height: 600px;
}

@media (max-width: 320px) {
  .payment-password-modal .modal-wrapper {
    width: 420px;
    min-height: 568px;
  }
}
.payment-discount-modal .modal-wrapper {
  width: 420px;
  height: 610px;
  border-top: 12px solid var(--ft-color-main);
}

.gym-treadmill-detail-modal .modal-wrapper {
  width: 440px;
  min-height: 600px;
}

.my-custom-modal-css .modal-wrapper {
  height: 50%;
  position: absolute;
  display: block;
  padding: 0 50px;
}

.select-option .modal-wrapper {
  min-width: 516px;
}

.calendar-ex-item {
  font-size: 12px;
  padding: 4px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: -1px -1px var(--ft-color-gray2), 1px 1px var(--ft-color-gray2);
}

.tooltip-style {
  position: absolute;
  overflow: visible;
  top: 5px;
  left: 35px;
  background-color: var(--ft-color-black1);
  box-shadow: 0 0 6px 0 black;
  padding: 10px 12px 10px 12px;
  color: white;
  width: 360px;
  font-size: 12px;
  border-radius: 4px;
}

.opacity-container div {
  opacity: 0.1;
}

.opacity-container:hover div {
  opacity: 1;
}

.center-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

textarea {
  --padding: 0 10px;
}

.not-yet {
  --background: gray !important;
  background: gray !important;
}
.not-yet > * {
  --background: gray !important;
  background: gray !important;
}
.not-yet.prod-mode {
  display: none !important;
}

ion-modal.auto-height {
  --height: auto;
  overflow: visible;
}
ion-modal.auto-height .modal-wrapper {
  overflow: visible;
  width: 600px;
}
ion-modal.auto-height .modal-wrapper .ion-page {
  overflow: visible;
  position: relative;
  contain: none;
}
ion-modal.auto-height .modal-wrapper .ion-page .modal-content {
  overflow: auto;
}

.member-delete-popup .modal-wrapper {
  min-height: 236px;
  --height: auto;
  width: 400px;
}

.fixed-list-shadow {
  z-index: 0;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

ion-select.round-border {
  border: 1px solid #b0bac5;
  border-radius: 4px;
  padding: 4px 8px;
}

.child-item-center {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
}

.item-border-top {
  border-top: 1px solid var(--item-border-color);
}

.item-border-bottom {
  border-bottom: 1px solid var(--item-border-color);
}

.trainer-bonus .popover-content {
  --width: 430px !important;
}

ion-title {
  font-weight: bold;
}
ion-title ion-label {
  font-weight: bold;
}

.font6 {
  font-size: 6px !important;
}

.font7 {
  font-size: 7px !important;
}

.font8 {
  font-size: 8px !important;
}

.font10 {
  font-size: 10px !important;
}

.font11 {
  font-size: 11px !important;
}

.font12 {
  font-size: 12px !important;
}

.font13 {
  font-size: 13px !important;
}

.font14 {
  font-size: 14px !important;
}

.font15 {
  font-size: 15px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 16px !important;
}

.font25 {
  font-size: 25px !important;
}

.font30 {
  font-size: 30px !important;
}

.bold {
  font-weight: bold !important;
}

.full-alert .alert-wrapper {
  width: 100%;
}

.web-select-option-popup .alert-wrapper {
  width: 400px;
  max-width: 90%;
}

.gym-select-option .alert-radio-group {
  border-top: none;
}
.gym-select-option .alert-head {
  padding-top: 0;
  padding-bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 44px;
  padding: 2px 24px;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.element-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.exercise-test-contents .item-border-bottom {
  border-color: var(--ft-color-gray2);
}
.exercise-test-contents .test-page-header {
  flex: none;
  font-size: 20px;
}
.exercise-test-contents .test-page-header ion-label {
  font-weight: bold;
}
.exercise-test-contents ion-content {
  --background: transparent;
  padding: 8px 8px 0 8px;
  display: block;
  --padding-top: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
}
.exercise-test-contents ion-item {
  --background: transparent;
}
.exercise-test-contents ion-toolbar {
  --background: transparent;
  font-size: 14px;
}
.exercise-test-contents ion-col {
  padding: 0;
}
.exercise-test-contents ion-card {
  --background: #fff;
  margin: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}
.exercise-test-contents ion-button {
  font-size: 14px;
}
.exercise-test-contents ion-card-header {
  height: 50px;
  border-bottom: 1px solid var(--item-border-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.exercise-test-contents ion-card-header ion-label {
  font-weight: bold;
}
.exercise-test-contents ion-card-header ion-label:nth-child(n+2) {
  margin-left: 16px;
}
.exercise-test-contents ion-card-header ion-button {
  height: 20px;
  margin: 0;
}
.exercise-test-contents .video-grid-container {
  display: grid;
  width: calc(100% - 24px);
  grid-template-columns: repeat(auto-fill, minmax(249px, 249px));
  column-gap: 12px;
  row-gap: 12px;
  justify-content: center;
}
.exercise-test-contents .video-grid-container .video-card {
  height: 240px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--item-border-color);
  border-radius: 4px;
  cursor: pointer;
}
.exercise-test-contents .video-grid-container .video-card:hover .video-title {
  color: var(--ft-color-black2);
}
.exercise-test-contents .video-grid-container .video-card:hover .video-desc {
  color: var(--ft-color-black2);
}
.exercise-test-contents .video-grid-container .video-card .video-thumbnail {
  width: 100%;
  height: 140px;
  display: flex;
  position: relative;
}
.exercise-test-contents .video-grid-container .video-card .video-thumbnail ion-img {
  object-fit: cover;
  width: 100%;
}
.exercise-test-contents .video-grid-container .video-card .play-icon {
  position: absolute;
  font-size: 36px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}
.exercise-test-contents .video-grid-container .video-card .video-title {
  font-size: 14px;
  font-weight: bold;
  transition: 0.25s;
  letter-spacing: -0.04px;
}
.exercise-test-contents .video-grid-container .video-card .video-desc {
  font-size: 13px;
  word-break: keep-all;
  white-space: pre-line;
  transition: 0.25s;
}
.exercise-test-contents .exercise-round-button {
  background: #fff;
}
.exercise-test-contents .input-container {
  --padding-top: 4px;
  --padding-bottom: 4px;
  min-height: unset;
}
.exercise-test-contents .table-gray {
  border-radius: 4px;
  overflow: hidden;
  background-color: rgba(239, 242, 246, 0.3);
}
.exercise-test-contents .table-gray .header {
  background-color: var(--ft-color-gray2);
}
.exercise-test-contents .table-gray .gray-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  height: 40px;
}
.exercise-test-contents .table-gray ion-input {
  --background: white;
}
.exercise-test-contents .table-white {
  border: 1px solid var(--ft-color-gray2);
  border-radius: 8px;
  font-size: 14px;
}
.exercise-test-contents .item-border-right {
  border-right: 1px solid var(--item-border-color);
}
.exercise-test-contents .image-table {
  font-size: 12px;
  padding: 10px 0;
}
.exercise-test-contents .image-table ion-text:first-child {
  margin-bottom: 15px;
  white-space: nowrap;
  font-size: 13px;
}
.exercise-test-contents .image-table .active-gray {
  color: var(--ft-color-gray6);
}
.exercise-test-contents .image-table .active-main {
  color: var(--ft-color-main);
}
.exercise-test-contents .image-table .inactive {
  color: var(--ft-color-gray3);
}
.exercise-test-contents.exercise-auto-height {
  --height: auto;
  --width: auto;
  overflow: visible;
}
.exercise-test-contents.exercise-auto-height .modal-wrapper {
  overflow: visible;
}
.exercise-test-contents.exercise-auto-height .modal-wrapper .ion-page {
  overflow: visible;
  position: relative;
  contain: none;
}
.exercise-test-contents.exercise-auto-height .modal-wrapper .ion-page .modal-content {
  overflow: auto;
}
.exercise-test-contents .guide-title {
  font-size: 24px;
  word-break: keep-all;
}

.admin-contents ion-col {
  position: relative;
}
.admin-contents ion-col ft-dash-card {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: inherit;
  height: inherit;
  overflow: hidden;
}
.admin-contents ion-col ft-dash-card ft-dash-card-item {
  overflow: hidden;
}
.admin-contents .dash-2-desc {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}
.admin-contents .dash-2-desc :nth-child(1) {
  flex: 1;
}
.admin-contents .dash-2-desc :nth-child(2) {
  flex: 1;
  text-align: right;
}
.admin-contents .dash-3-desc {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}
.admin-contents .dash-3-desc :nth-child(1) {
  flex: none;
  width: 60px;
}
.admin-contents .dash-3-desc :nth-child(2) {
  flex: 1;
  text-align: right;
  margin-left: auto;
}
.admin-contents .dash-3-desc :nth-child(3) {
  flex: 1;
  text-align: right;
}

.router-contents {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: unset;
}
.router-contents ion-router-outlet {
  flex: 1;
  position: relative;
}
.router-contents ion-router-outlet.no-padding-top > .ion-page ion-content {
  --padding-top: 0;
}

.tooltip-class {
  font-size: 14px;
  text-align: left !important;
  word-break: keep-all;
}

.tooltip-custom {
  position: absolute !important;
  right: 0px;
  top: 0px;
  width: 0px !important;
  height: 0px !important;
  z-index: 99999;
  border-top: 20px solid red;
  border-left: 20px solid transparent;
}
@media screen and (max-width: 768px) {
  .tooltip-custom {
    border-top: 14px solid red;
    border-left: 14px solid transparent;
  }
}

.tooltip-icon {
  font-size: 24px;
  padding: 16px;
  color: var(--ft-color-gray5);
  cursor: pointer;
}

.horizontal-radio-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.horizontal-radio-group ion-item {
  --inner-border-width: 0;
  float: left;
  --padding-top: 0;
  --padding-bottom: 0;
}
.horizontal-radio-group ion-item ion-radio {
  margin-right: 16px;
}

.report-container > * {
  display: block;
  width: fit-content;
  height: fit-content;
}

.report-wrapper {
  background-color: white;
  width: 741px;
  position: relative;
}
.report-wrapper .report-img {
  width: 100%;
  position: relative;
  z-index: 0;
  image-rendering: -webkit-optimize-contrast;
}
.report-wrapper .report-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
.report-wrapper .report-inner div,
.report-wrapper .report-inner span,
.report-wrapper .report-inner img {
  position: absolute;
}

.report > .user-info {
  display: block !important;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #000;
}
.report > .user-info.name {
  justify-content: left;
}
.report > .user-info.name, .report > .user-info.height, .report > .user-info.gender, .report > .user-info.indate {
  top: 68px;
}
.report > .user-info.age, .report > .user-info.weight, .report > .user-info.indate-time, .report > .user-info.tel {
  top: 88px;
}
.report > .user-info.name, .report > .user-info.age {
  width: 88px;
  left: 80px;
}
.report > .user-info.height, .report > .user-info.weight {
  width: 100px;
  left: 240px;
}
.report > .user-info.gender, .report > .user-info.indate-time {
  width: 108px;
  left: 400px;
}
.report > .user-info.indate, .report > .user-info.tel {
  width: 160px;
  left: 580px;
}

.schedule-flex-row-item {
  white-space: nowrap;
  font-size: 14px;
  --padding-start: 0;
}

ion-content.content-loading {
  width: 100%;
  height: 100%;
}
ion-content.content-loading ion-spinner {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 14px);
}

ion-spinner.center {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 14px);
}

.dot {
  display: inline-block;
  border-radius: 50%;
}

.hover-underline:hover {
  text-decoration: underline;
}

.gray-circle-button {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin: auto;
  line-height: 26px;
  font-size: 16px;
  background-color: var(--ft-color-gray1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.smtReport-table {
  width: 325px;
  height: 156px;
  display: flex;
  flex-direction: column;
}
.smtReport-table .smtReport-row {
  position: static !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.smtReport-table .smtReport-row span {
  position: static !important;
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #39537c;
}

.disabled-block {
  pointer-events: none;
  opacity: 0.4;
}

.schedule-view-header {
  flex: none;
  border-bottom: 1px solid var(--ft-color-gray2);
  border-left: 1px solid var(--ft-color-gray2);
  border-right: 1px solid var(--ft-color-gray2);
  font-size: 18px;
  --padding-start: 0px;
}
.schedule-view-header ion-label {
  font-weight: bold;
}
.schedule-view-header ion-buttons {
  margin: 0px;
}

.alert-checkbox-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

.alert-checkbox-label.sc-ion-alert-ios {
  white-space: pre-line !important;
}

.progress-bar-title {
  font-size: 14px;
  color: var(--ft-color-black1);
}

.progress-bar-subTitle {
  font-size: 13px;
  color: var(--ft-color-gray5);
}

.top-comment {
  text-align: center;
  font-size: 1.4rem;
  background-color: rgba(239, 242, 246, 0.5);
  padding: 15px 0;
}

.full-circle-chart {
  text-align: center;
}
.full-circle-chart.state {
  position: absolute;
  top: 40px;
  left: 0;
  font-size: 18px;
  color: #3d3e44;
  font-weight: bold;
}
.full-circle-chart.text-ko {
  position: absolute;
  top: 68px;
  left: 0;
  font-size: 11px;
  color: #667587;
}
.full-circle-chart.value {
  position: absolute;
  top: 84px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
  color: #495057;
}
.full-circle-chart.unit {
  position: absolute;
  top: 96px;
  left: 0;
  font-size: 10px;
  color: #b0bac5;
}

.upper-bullets .swiper-pagination {
  bottom: 24px !important;
}

.hover-effect:hover {
  background-color: var(--ion-item-background-hover, rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.04));
}

.sc-ion-modal-md-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.action-sheet-button.disabled {
  color: var(--ft-color-gray4);
  pointer-events: none;
}

.help-tooltip {
  font-size: 14px;
  text-align: left !important;
  word-break: keep-all;
  white-space: pre-line;
}

.report-component {
  zoom: 0.5;
  -webkit-text-size-adjust: 48%;
}

@media (max-width: 280px) {
  .report-component {
    zoom: 0.37;
    -webkit-text-size-adjust: 37%;
  }
}
@media (width: 320px) {
  .report-component {
    zoom: 0.42;
    -webkit-text-size-adjust: 42%;
  }
}
@media (width: 360px) {
  .report-component {
    zoom: 0.48;
    -webkit-text-size-adjust: 48%;
  }
}
.mobile-line-height {
  line-height: 20px;
}

.shortcut-hint {
  position: absolute;
  display: inline-block;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 4px 12px;
  border-radius: 15px;
  background: var(--ft-color-caution);
  height: 30px;
}

/*
*  ion-button 컴포넌트 fill="clear" 속성 적용하여 사용할 경우
*  disabled 전환 또는 버튼 클릭(activated) 상태 변경 시 화면 출력 오류 문제 방지
*/
ion-button[fill=clear]:not(.button-disabled) {
  --opacity: 1 !important;
  opacity: 1 !important;
  white-space: break-spaces;
}

ion-button[disabled] {
  --opacity: 0.6 !important;
  opacity: 0.6 !important;
}

.maintain-ratio-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maintain-ratio-container .maintain-ratio-box {
  max-width: 100%;
  max-height: 100%;
  /* Firefox, we're waiting for you! */
  aspect-ratio: 1.2;
}
.maintain-ratio-container .maintain-ratio-box:after {
  /* Some empty block content to force aspect ratio */
  display: block;
  content: "";
  min-width: 100vw;
  max-height: 100vw;
}

/*
**  chart.js의 반응형 차트를 적용하려면, 부모가 반응형이어야 한다.
**  [Responsive Charts](https://www.chartjs.org/docs/latest/configuration/responsive.html#important-note)
*/
.responsive-chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.responsive-chart-container .responsive-chart-item {
  height: 100%;
  width: 100%;
}

.line-with-text {
  margin: 5px;
  width: 43%;
  border: 0.3px solid #eff2f6;
}

.social-login-button {
  border-radius: 4px;
  margin: 5px 15px 5px 15px;
  font-size: 15px;
  height: 36px;
  vertical-align: center;
  align-items: center;
  text-transform: none;
  width: 90%;
  align-content: center;
}

.action-sheet-cancel * {
  color: red;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-content.full-card-content {
  --background: var(--ft-color-gray2);
}
ion-content.full-card-content ion-card {
  margin: 0 0 8px 0;
  width: 100;
  border-radius: 0px;
  box-shadow: none;
}

/*
*  iframe 사용 시, 부모 컨테이너에 padding-bottom 56.25% 줌으로써,
*  16: 9 비율 반응형 iframe 사용 가능
*/
.flexible-video-box {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.flexible-video-box iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.focusable-dash-card {
  cursor: pointer;
}
.focusable-dash-card.activate {
  border: 2px solid var(--ft-color-main) !important;
}

.mobile-select-option .alert-radio-label.sc-ion-alert-md {
  white-space: pre-line !important;
  word-break: keep-all !important;
}

.smt-recent-record {
  color: --ft-color-gray6;
  font-size: 13px;
}

.group-logo {
  background: #a5e0fb;
  border-radius: 8px;
  padding: 4px;
  margin-left: 4px;
  overflow: visible !important;
  font-size: smaller !important;
  font-weight: bold;
}

.scrollable-y .menu-list {
  overflow-y: scroll;
}
.scrollable-y .popover-viewport {
  overflow-y: auto;
}