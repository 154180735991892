// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/

:root {
  // --web-header-height: 104px;
  --web-header-height: 80px;
  // --web-content-width: 1100px;
  --web-content-width: 100%;
  --web-content-min-width: 1024px;
  --web-content-max-width: 1280px;
  --web-content-padding-top: 8px;
  --web-content-padding-bottom: 4px;
  --web-content-padding-left: 16px;
  --web-content-padding-right: 16px;


  --font-size: 14px;
  // fitt-note: scss 변수는 관습적으로 --를 붙임. project내에서 정의한 변수를 구별하기 위해 --ft prefix 유지.

  --ft-color-gray1-light: rgba(var(--ft-color-gray1-rgb), 0.1); //#f2f5f8

  --ft-color-white: #ffffff;
  --ft-color-white-rgb: 255, 255, 255;

  --ft-color-gray1: #eff2f6;
  --ft-color-gray1-rgb: 239, 242, 246;

  --ft-color-gray2: #dfe6ee;
  --ft-color-gray2-rgb: 223, 230, 238;

  --ft-color-gray3: #cfd6de;
  --ft-color-gray3-rgb: 207, 214, 222;

  --ft-color-gray4: #b0bac5;
  --ft-color-gray4-rgb: 176, 186, 197;

  --ft-color-gray5: #8091a4;
  --ft-color-gray5-rgb: 128, 145, 164;

  --ft-color-gray6: #667587;
  --ft-color-gray6-rgb: 102, 117, 135;

  --ft-color-black1: #495057;
  --ft-color-black1-rgb: 73, 80, 87;

  --ft-color-black2: #1e1e1e;
  --ft-color-black2-rgb: 30, 30, 30;

  --ft-color-main: #206ff6;
  --ft-color-main-rgb: 32, 111, 246;

  --ft-color-sub1: #01c2b2;
  --ft-color-sub1-rgb: 1, 194, 178;

  --ft-color-sub2: #d4fe42;
  --ft-color-sub2-rgb: 212, 254, 66;

  --ft-color-sub3: #c35bf5;
  --ft-color-sub3-rgb: 195, 91, 245;

  --ft-color-sub4: #ff2d55;
  --ft-color-sub4-rgb: 255, 45, 85;

  --ft-color-error: #ff2d55;
  --ft-color-error-rgb: 255, 45, 85;

  --ft-color-caution: #f7ba02;
  --ft-color-caution-rgb: 247, 186, 2;

  --ft-color-menubar: #fdfdfd;

  --ft-item-height: 62px;

  --ft-color-naver: #03c75a;
  --ft-color-naver-rgb: 3, 199, 90;
  --health-color: #ff6384;
  --pt-color: #ffcd56;
  --gx-color: #4bc0c0;
  --point-color: #c35bf5;
  --etc-color: #36a2eb;
  --etcs-color: #36a2eb;

  --ft-color-runner-interval-slow-rgb: 64, 220, 139;
  --ft-color-runner-interval-fast-rgb: 255, 213, 140;

  /** primary **/
  --ion-color-primary: var(--ft-color-main);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: var(--ft-color-sub4);
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  // --ion-color-medium: #989aa2;
  --ion-color-medium: var(--ft-color-black1);
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: var(--ft-color-gray2);
  --ion-color-light-rgb: 223, 230, 238;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c4cad1;
  --ion-color-light-tint: #e2e9f0;

  --ion-color-naver-green: #03c75a;

  --item-border-color: var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --ion-item-border-color: var(--ft-color-gray2);
}

$breakpoint-mobile: 335px;
$breakpoint-tablet: 758px;
$breakpoint-desktop: 1024px;

.ion-color-white {
  --ion-color-base: #ffffff !important;
  --ion-color-base-rgb: 255, 255, 255 !important;
  --ion-color-contrast: #ffffff !important;
  --ion-color-contrast-rgb: 255, 255, 255 !important;
  --ion-color-shade: #ffffff !important;
  --ion-color-tint: #ffffff !important;
}

.ion-color-main {
  --ion-color-base: var(--ft-color-main) !important;
  --ion-color-base-rgb: 32, 111, 246 !important;
  --ion-color-tint: rgba(var(--ion-color-base-rgb), 0.8) !important;
}

.ion-color-sub1 {
  --ion-color-base: var(--ft-color-sub1) !important;
}

.ion-color-sub2 {
  --ion-color-base: var(--ft-color-sub2) !important;
}

.ion-color-sub3 {
  --ion-color-base: var(--ft-color-sub3) !important;
}

.ion-color-sub4 {
  --ion-color-base: var(--ft-color-sub4) !important;
}

.ion-color-error {
  --ion-color-base: var(--ft-color-error) !important;
}

.ion-color-caution {
  --ion-color-base: var(--ft-color-caution) !important;
}

.ion-color-gray1 {
  --ion-color-base: var(--ft-color-gray1) !important;
}

.ion-color-gray2 {
  --ion-color-base: var(--ft-color-gray3) !important;
}

.ion-color-gray3 {
  --ion-color-base: var(--ft-color-gray3) !important;
}

.ion-color-gray4 {
  --ion-color-base: var(--ft-color-gray4) !important;
}

.ion-color-gray5 {
  --ion-color-base: var(--ft-color-gray5) !important;
}

.ion-color-gray6 {
  --ion-color-base: var(--ft-color-gray6) !important;
}

.ion-color-black1 {
  --ion-color-base: var(--ft-color-black1) !important;
}

.ion-color-black2 {
  --ion-color-base: var(--ft-color-black2) !important;
}
